// @import "_mixins/font-face"

// +font-face("Gilroy-Light", "../fonts/Gilroy-Light/Gilroy-Light")
@font-face {
	font-family: 'Gilroy-Light';
	src: url(../fonts/Gilroy-Light/Gilroy-Light.eot);
	src: url(../fonts/Gilroy-Light/Gilroy-Light.eot) format('embedded-opentype'), url(../fonts/Gilroy-Light/Gilroy-Light.woff) format('woff'), url(../fonts/Gilroy-Light/Gilroy-Light.ttf) format('truetype'), url(../fonts/Gilroy-Light/Gilroy-Light.svg) format('svg');
	font-weight: normal;
	font-style: normal; }
@font-face {
	font-family: fontello;
	src: url(../fonts/fontello/fontello.eot);
	src: url(../fonts/fontello/fontello.eot) format('embedded-opentype'), url(../fonts/fontello/fontello.woff) format('woff'), url(../fonts/fontello/fontello.ttf) format('truetype'), url(../fonts/fontello/fontello.svg) format('svg');
	font-weight: normal;
	font-style: normal; }
