@import "vars";
@import "fonts";
@import "libs";

::placeholder {
	color: #666; }

::selection {
	background-color: $accent;
	color: #fff; }

html, body {
	height: 100%; }

body {
	font-size: 18px;
	min-width: 320px;
	position: relative;
	line-height: 1.67;
	font-family: $default-font;
	overflow-x: hidden;
	color: $main;
	background-color: $blue;
	input, textarea {
		border: #666 1px solid;
		outline: none;
		&:focus:required:invalid {
			border-color: red; }
		&:required:valid {
			border-color: green; } } }

.img-responsive {
	display: block;
	max-width: 100%;
	height: auto;
	&.img-width {
		width: 100%; } }

.text-center {
	text-align: center; }

.wrapper {
	position: relative;
	overflow: hidden; }

// reset styles start
ul {
	margin: 0;
	padding: 0;
	list-style-type: none; }

p {
	margin: 0;
	padding: 0; }

h1,h2,h3,h4,h5,h6 {
	margin: 0;
	padding: 0; }

a {
	&:hover {
		outline: none;
		text-decoration: none; }
	&:focus {
		outline: none;
		text-decoration: none; } }

input, button {
	&:hover,&:focus {
		outline: none; } }
// reset styles end

// base clasess start

.section-header {
	position: relative;
	.bg-title {
		display: none;
		left: 50%;
		transform: translateX(-50%);
		top: -10px;
		font-size: 152px;
		font-family: $Raleway;
		font-weight: 700;
		background: -webkit-linear-gradient(#d047e4 , #ff4e3d );
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		opacity: 0.08;
		line-height: 1;
		white-space: nowrap;
		position: absolute; }

	&--center {
		text-align: center; }
	&--tire {
		h4 {
			padding-left: 50px;
			position: relative;
			&:after {
				content: "";
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				width: 40px;
				left: 0;
				border-bottom: 1px solid #545c79; } } }
	&--white {
		h4 {
			color: #ffffff;
			&:after {
				border-bottom: 1px solid #ffffff; } } }
	&--big-margin {
		margin-bottom: 35px; }
	&--medium-margin {
		margin-bottom: 35px; }
	&--small-margin {
		margin-bottom: 30px; } }


h1 {
	font-size: 40px;
	line-height: 45px;
	font-family: $Raleway;
	font-weight: 700;
	color: #ffffff;
	max-width: 545px;
	span {
		display: inline;
		font-family: $Raleway;
		font-weight: 100; } }

h2 {
	font-family: $Raleway;
	font-weight: 700;
	font-size: 30px;
	line-height: 35px;
	color: #fff;
	span {
		display: block;
		font-weight: 100; } }

h3 {
	font-size: 18px;
	font-family: $Raleway;
	font-weight: 700;
	color: #333333; }

h4 {
	font-size: 12px;
	letter-spacing: 1.2px;
	color: #545c79;
	text-transform: uppercase;
	font-family: $Catamaran;
	font-weight: 700;
	margin-bottom: 15px; }

section {
	position: relative;
	z-index: 1; }

.section {
	padding: 60px 0;
	&--no-pad-bot {
		padding-bottom: 0; }
	&--no-pad-top {
		padding-top: 0; } }

.social-block {
	margin-bottom: 30px;
	&__title {
		color: #ffffff;
		margin-bottom: 20px; } }

.social-list {
	display: flex;
	align-items: center;
	justify-content: center;
	&__item {
		margin-right: 10px;
		&:last-child {
			margin-right: 0; } }
	&__link {
		width: 40px;
		height: 40px;
		border: 1px solid rgba(255,255,255,0.5);
		position: relative;
		transition: border 0.4s;
		display: block;
		border-radius: 50%;
		.fontello-icon {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%); }
		&:hover {
			border: 1px solid rgba(255,255,255,1); } } }

.fontello-icon {
	font-family: fontello;
	font-style: normal;
	font-weight: normal;
	speak: none;
	display: inline-block;
	text-decoration: inherit;
	text-align: center;
	font-variant: normal;
	text-transform: none;
	line-height: 1em;
	color: #fff; }

.btn {
	border-radius: 5px;
	height: 60px;
	line-height: 60px;
	color: #ffffff;
	transition: background-color 0.4s, color 0.4s, box-shadow 0.4s;
	font-size: 16px;
	display: inline-block;
	border: none;
	font-family: $Catamaran;
	font-weight: 600;
	letter-spacing: 0.8px;
	&--medium {
		padding: 0 40px; }
	&--big {
		padding: 0 60px; }
	&--small {
		padding: 0 30px; }
	&--uppercase {
		text-transform: uppercase;
		font-size: 14px; }
	&--orange {
		box-shadow: 0 3px 32px rgba(236, 117, 81, 0.54);
		background-image: linear-gradient(to right, #ed8a4c 0%, #ea485c 100%);
		position: relative;
		overflow: hidden;
		transition: box-shadow 0.4s;
		span {
			position: relative;
			z-index: 2; }
		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: $orange;
			opacity: 0;
			transition: opacity 0.4s ease-in-out; }
		&:hover {
			box-shadow: 0 3px 32px rgba(237, 130, 78, 0.54);
			color: #ffffff;
			&:after {
				opacity: 1; } } }
	&--blue {
		box-shadow: 0 3px 32px rgba(0, 231, 234, 0.54);
		background-color: $lblue;
		transition: background-color 0.4s, box-shadow 0.4s;
		&:hover {
			background-color: #333333;
			color: #ffffff;
			box-shadow: 0 3px 32px rgba(16, 203, 188, 0.54);
			background-color: #2ddfd1; } } }



// base claesses end

// header start
.header {
	position: absolute;
	top: 0px;
	left: 0;
	width: 100%;
	z-index: 10;
	height: 90px;
	padding: 0 10px;
	border-bottom: 1px solid transparent;
	display: flex;
	justify-content: space-between;
	align-items: center;
	transition: border-bottom 0.4s, background-color 0.4s;
	&__right {
		display: none;
		align-items: center; }
	&.sticky {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		background-color: $blue;
		z-index: 11;
		height: 90px;
		border-bottom: 1px solid #333853;
		transition: background-color 0.4s;
		padding: 0 10px; } }

.logo {
	display: flex;
	align-items: center;
	transition: opacity 0.4s;
	position: relative;
	top: -3.5px;
	&__img {
		width: 115px;
		height: 51px;
		background-image: url(../img/logo_e4c.png);
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
		margin-right: 13px;
		transition: background-image 0.4s; }
	&__img--big {
		width: 150px;
		margin: 0 auto; }
	&__title {
		font-size: 27px;
		color: #fff;
		font-family: "Gilroy-Light"; }

	&:hover {
		opacity: 0.5; }
	&--color {
		.logo__img {
			background-image: url(../img/Logo_gradient.svg); }
		.logo__title {
			color: #777777; } } }

.btn-menu {
	position: relative;
	justify-content: center;
	align-items: flex-end;
	flex-direction: column;
	cursor: pointer;
	text-align: right;
	margin-left: 25px;
	display: block;
	div {
		width: 32px;
		height: 3px;
		min-height: 3px;
		transition: 0.3s;
		backface-visibility: hidden;
		-moz-transition-duration: 0.3s;
		-o-transition-duration: 0.3s;
		-webkit-transition-duration: 0.3s;
		transition-duration: 0.3s;
		margin-bottom: 6px;
		border-radius: 1.5px;
		position: relative;
		background-color: #fff;
		&:first-child {
			&:after {
				content: '';
				position: absolute;
				top: 0;
				right: 0;
				width: 100%;
				height: 100%;
				background-color: #fff;
				border-radius: 1.5px;
				transition: 0.4s; } }
		&:last-child {
			margin-bottom: 0;
			&:after {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: #fff;
				border-radius: 1.5px;
				transition: 0.4s; } } }

	&.on {
		top: -5px; } }

.btn-menu.on .one {
	transform: rotate(45deg) translate(6px, 2px);
	margin-bottom: 0;
	&:after {
		width: 100%;
		background-color: #777777; } }

.btn-menu.on .two {
	opacity: 0;
	margin-bottom: 0; }

.btn-menu.on .three {
	transform: rotate(-45deg) translate(2px, 2px);
	width: 32px;
	margin-bottom: 0;
	&:after {
		width: 100%;
		background-color: #777777; } }

.fixed-menu {
	position: fixed;
	background-color: $blue;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	z-index: 12;
	visibility: hidden;
	overflow-x: hidden;
	overflow-y: scroll;
	opacity: 0;
	transition: 0.4s;
	&.open {
		visibility: visible;
		display: block;
		opacity: 1; }
	&__header {
		width: 100%;
		height: 90px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0px 10px 0; }
	&__content {
		padding: 20px 90px 20px; }
	.select-styled.active {
		color: rgba(119, 119, 119, 1); }
	.select-options {
		right: -15px;
		top: 45px; }
	.select-styled, .btn-sign-up {
		color: #fff; }
	.select {
		margin-bottom: 30px; }
	.btn-wrap {
		display: flex; }
	.btn-menu {
		div {
			background-color: #ffffff;
			&.one, &.three {
				&:after {
					background-color: #ffffff; } } } } }

.btn-close {
	svg {
		width: 28px;
		height: 28px;
		path {
			fill: #ffffff; } } }


.mob-menu {
	margin-bottom: 40px;
	&__item {
		text-align: left;
		margin-bottom: 10px;
		&:last-child {
			margin-bottom: 0; } }
	&__link {
		color: #ffffff;
		position: relative;
		transition: 0.2s;
		white-space: nowrap;
		&:after {
			content: '';
			position: absolute;
			bottom: 0px;
			left: 0;
			width: 100%;
			border-bottom: 2px solid $lblue;
			transition: 0.4s;
			opacity: 0; }
		&:hover {
			color: #ffffff;
			&:after {
				opacity: 1; } } } }

.menu {
	display: none;
	align-items: center;
	&__item {
		margin-right: 20px;
		&:last-child {
			margin-right: 0; } }
	&__link {
		color: #ffffff;
		position: relative;
		transition: 0.2s;
		white-space: nowrap;
		&:after {
			content: '';
			position: absolute;
			bottom: -9px;
			left: 0;
			width: 100%;
			border-bottom: 2px solid #ffffff;
			transition: 0.4s;
			opacity: 0; }
		&:hover {
			color: #ffffff;
			&:after {
				opacity: 1; } } } }

.select-hidden {
	display: none;
	visibility: hidden;
	padding-right: 10px; }

$background: #e74c3c;
$select-color: #fff;
$select-background: #000;

.select {
	cursor: pointer;
	display: inline-block;
	position: relative;
	color: $select-color;
	width: 35px;
	height: 30px;
	font-size: 16px;
	color: #ffffff;
	box-sizing: border-box;
	margin-right: 30px; }
.select-styled {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 0;
	border-radius: 0px;
	transition: 0.4s;
	color: #ffffff;
	font-size: 16px;
	text-transform: uppercase;
	z-index: 1;
	&:after {
		content: "";
		width: 10px;
		height: 6px;
		background-image: url(../img/down-chevron.svg);
		background-size: 10px 6px;
		background-repeat: no-repeat;
		position: absolute;
		top: 9px;
		right: -15px;
		transition: 0.4s; }
	&:focus {
		outline: none;
		text-decoration: none; }
	&:active, &.active {
		color: rgba(255,255,255,0.5);
		&:after {
			transform: rotateX(-180deg);
			opacity: 0.5; } }
	&:hover {
		color: rgba(255,255,255,0.5);
		&:after {
			opacity: 0.5; } } }

.select-options {
	display: none;
	width: 80px;
	background-color: #fff;
	position: absolute;
	top: 45px;
	right: 0px;
	z-index: 2;
	margin: 0;
	padding: 0;
	list-style: none;
	padding: 24px 28px 22px 29px;
	&:before {
		content: '';
		position: absolute;
		top: -10px;
		right: 0;
		width: 0;
		height: 0;
		border-left: 10px solid transparent;
		border-right: 0px solid transparent;
		border-bottom: 10px solid #fff; }
	li {
		color: #ffffff;
		margin: 0;
		transition: 0.4s;
		font-size: 16px;
		text-transform: uppercase;
		color: #777777;
		margin-bottom: 15px;
		&:last-child {
			margin-bottom: 0; }
		&:hover {
			opacity: 0.5; }
		&[rel="hide"] {
			display: none; } } }

.btn-sign-in, .btn-sign-up {
	// width: 90px
	padding: 0px 20px;
	height: 50px;
	border-radius: 5px;
	background-color: $green;
	line-height: 50px;
	color: #ffffff;
	font-size: 16px;
	text-align: center;
	display: inline-block;
	transition: 0.4s;
	white-space: nowrap;
	margin-left: 10px;
	&:first-child {
		margin-left: 0; }
	&:hover {
		color: #fff;
		background-color: #2add82; } }
.btn-sign-in {
	background-color: transparent; }

// header end

// promo start block
.promo {
	padding-top: 150px;
	padding-bottom: 50px;
	position: relative;
	z-index: 2;
	// background-image: url(../img/header-image-file.jpeg)
	background-color: #1b1925;

	&::before {
		content: '';
		top: 0;
		left: 0;
		display: block;
		z-index: 3;
		position: absolute;
		width: 70%;
		height: 101%;
		background: linear-gradient(90deg, #202641 0%, rgba(27, 25, 37, 0) 100%) 0% 0% no-repeat;
		background-color: transparent;
		-o-background-origin: padding-box;
		-moz-background-origin: padding-box;
		-webkit-background-origin: padding-box; }

	.header-img {
		left: 50%;
		width: 100%;
		bottom: 0%;
		height: 100%;
		position: absolute;
		transform: translate(-50%, 0%);
		object-fit: cover; }

	.header-video {
		top: 0;
		left: 0;
		right: 50%;
		width: auto;
		bottom: 0;
		height: auto;
		opacity: 1;
		position: absolute;
		max-width: 100%;
		min-width: 100%;
		max-height: 100%;
		min-height: 100%;
		object-fit: cover;
		transition: opacity 1s ease;

		&::-webkit-media-controls {
  			display: none; } }

	.bg-layer-1 {
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0.3;
		position: absolute;
		transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
		background-image: radial-gradient(at center, #6667ef 0%, #7cfef1 100%); }

	.bg-layer-2 {
		left: 0;
		width: 100%;
		bottom: -1px;
		height: 373px;
		position: absolute;
		background: transparent linear-gradient(0deg, #202641 0%, rgba(27, 25, 37, 0) 100%) 0% 0% no-repeat padding-box; }

	h1 {
		margin-bottom: 20px; }
	p {
		margin-bottom: 50px;
		max-width: 600px; }
	&__btns-wrap {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 45px;
		.btn {
			width: 100%;
			text-align: center;
			&:first-child {
				margin-bottom: 10px; } } }
	.container {
		position: relative;
		z-index: 4; }
	&__img {
		position: absolute;
		top: -100px;
		right: -260px;
		display: none; }
	&__content {
		display: block; } }

.timer {
	margin: 0 0 75px 0;
	display: flex;
	justify-content: flex-start;
	&-wrap {
		position: relative; }
	&__titles {
		display: flex;
		width: 100%;
		justify-content: space-between;
		font-size: 14px;
		color: #ffffff;
		font-family: $Catamaran;
		font-weight: 400;
		text-transform: uppercase;
		letter-spacing: 0.7px;
		position: absolute;
		bottom: -30px;
		left: 0;
		max-width: 515px;
		div {
			width: calc(100% - 60px);
			text-align: center;
			margin-right: 20px;
			&:last-child {
				margin-right: 0; } } } }

.flip-clock-wrapper ul {
	width: 46px;
	height: 57px;
	background-color: #fffefc; }

.flip-clock-wrapper ul li {
	line-height: 57px; }

.flip-clock-wrapper ul li a div div.inn {
	background-color: #fffefc;
	font-size: 40px;
	font-family: $Roboto;
	font-weight: 500;
	color: #1f2641;
	text-shadow: none; }

.flip-clock-divider {
	height: 66px; }

.flip-clock-dot {
	display: none; }



.flip-clock-divider .flip-clock-label {
	top: auto;
	bottom: -35px;
	color: #ffffff;
	font-size: 14px;
	font-family: $Catamaran;
	font-weight: 400;
	text-transform: uppercase;
	letter-spacing: 0.7px;
	display: none; }

.payments {
	display: flex;
	align-items: center;
	img {
		margin-right: 20px;
		&:last-child {
			margin-right: 0; } } }

.scroll-down {
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	width: 40px;
	height: 40px;
	background-image: url(../img/scroll-down.png);
	cursor: pointer; }
// promo end block


// services section start
.services {
	position: relative;
	&__bg {
		position: absolute;
		top: 100px;
		left: 50%;
		transform: translateX(-50%);
		display: none; }
	.container {
		position: relative;
		z-index: 1; }
	&__cosmos {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		min-width: 1685px;
		width: 100%;
		object-fit: cover; } }

.service {
	width: 100%;
	height: 250px;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	margin-right: 18px;
	border-radius: 10px;
	margin-bottom: 20px;
	position: relative;
	&:hover {
		.service__bg {
			width: 105%;
			height: 105%; } }

	&:last-child {
		margin-right: 0; }
	img {
		margin-bottom: 20px;
		position: relative;
		z-index: 1; }
	&__title {
		position: relative;
		z-index: 1;
		font-size: 16px;
		font-family: $Raleway;
		color: #ffffff;
		font-weight: 700;
		letter-spacing: -0.32px; }
	&__bg {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 100%;
		height: 100%;
		border-radius: 10px;
		transition: width 0.4s, height 0.4s; } }
// services section end

// map start
.map {
	position: relative;
	.container {
		position: relative; }
	&__title-bg {
		position: absolute;
		top: 150px;
		left: -50px;
		opacity: 0.1;
		display: none; } }


.road {
	&__item {
		position: relative;
		padding-left: 40px;
		margin-bottom: 30px;
		&:after {
			content: '';
			position: absolute;
			top: 2px;
			left: 8.5px;
			width: 1px;
			border-left: 1px solid $lblue;
			height: calc(100% + 30px); }
		&:last-child {
			&:after {
				display: none; }
			margin-bottom: 0; } }
	&__item-active {
		&:after {
			border-left: 1px dashed #c6c6c6; }
		.road__item-metka {
			width: 31px;
			height: 31px;
			border: 2px solid $lblue;
			position: absolute;
			top: -5px;
			left: -7px;
			display: flex;
			justify-content: center;
			align-items: center;
			&:after {
				content: '';
				display: block;
				border-radius: 50%;
				width: 15px;
				height: 15px;
				background-color: $orange; } } }
	&__item-next {
		&:after {
			border-left: 1px dashed #c6c6c6; }
		.road__item-metka {
			border: 2px solid #c6c6c6; } }

	&__item-metka {
		width: 17px;
		height: 17px;
		border: 2px solid $lblue;
		border-radius: 50%;
		background-color: $blue;
		position: absolute;
		top: 2px;
		left: 0;
		z-index: 1; }
	&__item-title {
		font-size: 14px;
		font-family: $Raleway;
		color: #fff;
		font-weight: 700;
		margin-bottom: 10px; }
	p {
		line-height: 25px; } }
// map end

// partners-logo section start
.partners-logo {
	position: relative;
	.container {
		position: relative;
		z-index: 1; }
	&__block {
		background-color: #252d4b;
		border-radius: 3px;
		display: flex;
		justify-content: space-around;
		padding: 35px 0;
		align-items: flex-end;
		flex-wrap: wrap; }

	&__item {
		margin: 15px;
		text-align: center;
		display: flex;
		flex-direction: column;
		align-items: center;
		p {
			font-size: 14px;
			font-weight: 900;
			text-transform: uppercase;
			letter-spacing: 1.4px;
			color: #aab2cd; }
		img {
			margin-bottom: 15px; } }
	&__bg {
		position: absolute;
		top: -75px;
		left: 0;
		width: 100%;
		min-width: 1680px; } }

.rating {
	display: flex;
	li {
		width: 15px;
		height: 14px;
		background-repeat: no-repeat;
		background-size: contain;
		margin-right: 5px;
		&:last-child {
			margin-right: 0; } } }

// partners-logo section end

// cases section start
.cases {
	position: relative;
	z-index: 2;
	.container {
		position: relative;
		z-index: 1; }
	&__bg {
		position: absolute;
		top: 50px;
		left: 55%;
		transform: translateX(-50%); }
	.btn {
		margin: 0 auto;
		display: block;
		width: 180px;
		text-align: center;
		line-height: 55px;
		height: 55px; }
	&__elements {
		position: absolute;
		top: 0px;
		right: 0;
		display: none; } }

.cases__item {
	margin-bottom: 40px;
	text-align: center;
	&-icon {
		margin-bottom: 20px; }
	&-title {
		font-size: 20px;
		font-family: $Raleway;
		font-weight: 700;
		color: #ffffff;
		letter-spacing: -0.4px;
		margin-bottom: 24px; }
	&-text {
		color: #aab2cd;
		font-weight: 300; } }
// cases section end


// data section start

.data {
	position: relative;
	&__img {
		display: block;
		width: 100%;
		display: none; }
	&__bg {
		position: absolute;
		top: -70%;
		left: 50%;
		transform: translateX(-50%);
		min-width: 1680px;
		width: 100%;
		object-fit: cover; }
	.container {
		position: relative;
		z-index: 1; } }


.counter {
	&__item {
		text-align: center;
		margin-bottom: 20px;
		&:last-child {
			margin-bottom: 0; } }
	&__item-title {
		color: #ffffff;
		font-size: 20px;
		font-family: $Raleway;
		font-weight: 700;
		margin-bottom: 0px; }
	&__item-value {
		font-family: $Roboto;
		font-weight: 700;
		font-size: 30px;
		color: #ffffff; }
	&__item-value--percent {
		&:after {
			content: "%"; } } }

// data section end

// economy block start
.economy {
	padding-top: 60px;
	padding-bottom: 240px;
	position: relative;
	&__bg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		min-width: 1680px;

		object-fit: cover; }
	&__block {
		background-color: $purpure;
		padding: 35px 15px 30px;
		color: #ffffff;
		position: relative;
		z-index: 1; }
	p {
		margin-bottom: 25px; }
	ul {
		li {
			padding-left: 22px;
			position: relative;
			&:before {
				content: '';
				position: absolute;
				top: 11px;
				left: 0;
				background-image: url(../img/check.svg);
				background-repeat: no-repeat;
				background-size: contain;
				width: 10px;
				height: 9px; }
			span {
				font-weight: 700; } } }
	&__video-btn {
		width: 94px;
		height: 94px;
		background-color: $llblue;
		border-radius: 50%;
		position: absolute;
		bottom: -200px;
		left: 42%;
		transform: translateX(-50%);
		z-index: 2;
		display: block;
		box-shadow: 0 0 21px rgba(0, 214, 229, 0.79);
		transition: background-color 0.4s, box-shadow 0.4s;
		img {
			position: absolute;
			top: 50%;
			left: 55%;
			transform: translate(-50%, -50%); }
		&:before {
			top: -28px;
			left: -28px;
			content: '';
			width: 147px;
			height: 147px;
			position: absolute;
			border-radius: 50%;
			background-color: #00d1e4;
			opacity: 0.1;
			z-index: 0; }
		&:after {
			top: -57px;
			left: -57px;
			content: '';
			position: absolute;
			width: 202px;
			height: 202px;
			border-radius: 50%;
			background-color: #00d1e4;
			opacity: 0.1;
			z-index: 0; }
		&:hover {
			box-shadow: 0 0 21px rgba(0, 214, 229, 0.79);
			background-color: #46e6f1; } } }
// economy block end

// about section start
.about {
	position: relative;
	.container {
		position: relative;
		z-index: 2; }
	&__bg {
		position: absolute;
		bottom: -420px;
		left: 0;
		width: 100%;
		object-fit: cover;
		z-index: 1;
		min-width: 1680px; }
	p {
		margin-bottom: 25px; }
	ul {
		margin-bottom: 25px;
		li {
			margin-bottom: 12px;
			position: relative;
			padding-left: 23px;
			&:before {
				content: '';
				position: absolute;
				top: 12px;
				left: 0;
				width: 5px;
				height: 5px;
				background-color: #fff;
				border-radius: 50%; }
			&:last-child {
				margin-bottom: 0; } } }
	.align-items-center {
		display: flex; } }


// about section end

// facts section start
.facts {
	.container {
		position: relative;
		z-index: 1; }
	&__line {
		position: relative; }
	&__line-list {
		display: flex;
		justify-content: space-between; }
	&__bg {
		position: absolute;
		top: 20px;
		left: 50%;
		width: 100%;
		min-width: 1680px;
		transform: translateX(-50%); }
	&__item {
		text-align: center;
		&:nth-child(1) {
			margin-top: 80px; }
		&:nth-child(2) {
			margin-top: 110px; }
		&:nth-child(3) {
			margin-top: 60px; }
		&:nth-child(4) {
			margin-top: 10px; } }
	&__icon {
		margin-bottom: 22px; }
	&__title {
		font-size: 20px;
		font-family: $Raleway;
		font-weight: 700;
		color: #ffffff; } }


// facts section end

// token block start
.token {
	position: relative;
	z-index: 2;
	.container {
		position: relative; }
	&__img {
		position: absolute;
		top: 50px;
		left: -280px;
		display: none; }
	&__info-list {
		margin-bottom: 30px;
		li {
			font-family: $Catamaran;
			font-weight: 400;
			color: #aab2cd;
			margin-bottom: 5px;

			span {
				width: 200px;
				color: #ffffff;
				font-family: $Catamaran;
				font-weight: 700;
				margin-right: 15px; }
			&:last-child {
				margin-bottom: 0; } } }
	&__desc {
		margin-bottom: 30px; }
	&__desc-title {
		color: #ffffff;
		font-size: 25px;
		font-family: $Raleway;
		font-weight: 700;
		margin-bottom: 15px;
		line-height: 1; }
	&__desc-text {
		line-height: 30px;
		color: #aab2cd;
		p, b {
			display: block;
			margin-bottom: 15px;
			&:last-child {
				margin-bottom: 0; } } } }


// token block end

// docs section start
.docs {
	position: relative;
	.container {
		position: relative;
		z-index: 1; }
	&__bg {
		top: -150px;
		left: 0;
		position: absolute;
		min-width: 1748px;
		width: 100%;
		object-fit: cover; }
	.col-12 {
		&:last-child {
			.doc {
				margin-bottom: 0; } } } }

.doc {
	height: 275px;
	width: 100%;
	display: block;
	border-radius: 5px;
	transition: background-color 0.4s, transform 0.6s, box-shadow 0.4s;
	position: relative;
	margin-bottom: 10px;
	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border-radius: 5px;
		background-color: #fff;
		transition: box-shadow 0.6s, transform 0.6s; }
	&__content {
		z-index: 2;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		width: 100%;
		height: 100%;
		position: relative; }
	img {
		margin-bottom: 20px; }
	&__title {
		font-size: 18px;
		font-family: $Raleway;
		font-weight: 700;
		color: #1f2641; }
	&:hover {
		&:before {
			transform: scale(1.1);
			box-shadow: 0 3px 40px rgba(0, 0, 0, 0.55); }
		&:after {
			opacity: 1; } }
	img {
		width: 51px; } }

// docs section end

// data section start


.chart {
	width: 100%;
	height: 100%;
	margin: 0 auto 120px;
	max-width: 488px;
	max-height: 488px;
	display: flex;
	justify-content: center;
	align-items: center;
	&__row {
		margin-top: 100px; }
	&__bg {
		position: absolute;

		min-width: 488px;
		min-height: 488px; }
	&__wrap {
		width: 315px;
		height: 315px;
		position: relative; }
	&__title {
		font-size: 25px;
		font-family: $Raleway;
		font-weight: 700;
		margin-bottom: 15px;
		color: #ffffff;
		line-height: 1; }
	&__text {
		font-family: $Raleway;
		color: #aab2cd;
		margin-bottom: 30px; }
	&__legend {
		font-size: 16px;
		color: #ffffff;
		font-weight: 700;
		li {
			margin-bottom: 20px;
			&:last-child {
				margin-bottom: 0; } }
		span {
			height: 16px;
			display: inline-block;
			background-image: linear-gradient(to right, #ed8a4c 0%, #ea485c 100%);
			border-radius: 3px;
			margin-right: 20px; } } }

// data section end

// faq start block

$color: #00d1e4;
$border: 1px solid #3b4360;

.accordion {
	li {
		border-bottom: $border;
		position: relative;
		padding: 15px 0px 15px 0; }
	p {
		display: none;
		padding-top: 10px;
		font-size: 16px;
		line-height: 28px; }
	a {
		width: 100%;
		display: block;
		cursor: pointer;
		font-size: 18px;
		line-height: 22px;
		font-family: $Raleway;
		font-weight: 700;
		user-select: none;
		transition: color 0.4s;
		padding-right: 25px;
		color: #ffffff !important;
		&:after {
			content: '';
			position: absolute;
			top: 24px;
			right: 0;
			width: 19px;
			border-bottom: 3px solid $llblue; }
		&:before {
			content: '';
			position: absolute;
			top: 16px;
			right: 7.5px;
			width: 3px;
			height: 19px;
			transition: height 0.4s;
			border-left: 3px solid $llblue; }
		&:hover {
			color: $llblue !important; }
		&.active {
			&:before {
				height: 0; } } } }

// faq end block

// advisors block start
.advisors {
	padding-bottom: 20px; }
.advisor {
	text-align: center;
	margin-bottom: 40px;
	&__img {
		width: 230px;
		height: 234px;
		margin: 0 auto 20px;
		position: relative;
		display: block;
		&:before {
			content: '';
			position: absolute;
			top: -20%;
			left: -20%;
			width: 140%;
			height: 140%;
			background-image: url(../img/advisor-avatar-bg.png); }
		img {
			position: relative;
			z-index: 1;
			height: 100%;
			object-fit: cover;
			transition: transform 0.4s;
			&:hover {
				transform: scale(0.95); } } }
	&__sn {
		position: absolute;
		top: 30px;
		right: -25px;
		img {
			width: 45px;
			height: 45px; } }

	&__title {
		font-family: $Raleway;
		font-weight: 700;
		color: #ffffff;
		margin-bottom: 5px;
		line-height: 1; }
	&__post {
		font-size: 16px;
		color: #545c79;
		line-height: 1;
		margin-bottom: 15px; }
	&__content {
		position: relative;
		z-index: 1;
		display: flex;
		flex-direction: column;
		justify-content: start;
		margin-top: 15px; } }



// advisors block end

// team block start
.team {
	padding-bottom: 30px;
	position: relative;
	&__bg {
		position: absolute;
		top: 180px;
		left: 0;
		width: 100%;
		object-fit: cover;
		min-width: 1680px; }
	.container {
		position: relative;
		z-index: 1; } }
.team-member {
	margin-bottom: 30px;
	text-align: center;
	&__avatar {
		width: 109px;
		height: 109px;
		border: 2px solid #249584;
		border-radius: 50%;
		display: block;
		margin: 0 auto 15px;
		object-fit: cover; }
	&__name {
		color: #ffffff;
		margin-bottom: 10px;
		line-height: 1; }
	&__post {
		color: #545c79;
		font-size: 16px;
		margin-bottom: 17px;
		line-height: 1; }
	&__social {
		display: flex;
		justify-content: center;
		li {
			margin-right: 5px;
			&:last-child {
				margin-right: 0; } }
		a {
			display: block;
			transition: transform 0.4s;
			&:hover {
				transform: translateY(-5px); } }
		img {
			display: block;
			width: 23px;
			height: 23px; } } }

//team block end

// news start
.news {
	position: relative;
	.block-header {
		margin-bottom: 40px; } }

.news-carousel {
	&__item-img {
		object-fit: cover;
		display: block; }
	&__item {
		background-color: #252d4b;
		display: block;
		color: $black;
		margin: 20px 0 35px;
		&:hover {
			.news-carousel__item-body {
				box-shadow: 0 3px 27px rgba(0, 0, 0, 0.17); }
			.news-carousel__item-title {
				color: $llblue; } } }
	&__item-body {
		padding: 40px 49px 36px 38px;
		transition: box-shadow 0.4s; }
	&__item-subtitle {
		font-size: 16px;
		color: $llblue;
		font-family: $Catamaran;
		font-weight: 700;
		margin-bottom: 24px; }
	&__item-title {
		font-size: 20px;
		font-family: $Raleway;
		font-weight: 700;
		line-height: 30px;
		color: #ffffff;
		margin-bottom: 25px;
		transition: color 0.4s; }
	p {
		line-height: 30px;
		margin-bottom: 25px;
		color: #aab2cd; }
	&__item-data {
		color: #525a7b;
		font-size: 16px;
		font-family: $Catamaran;
		font-weight: 700; }

	.owl-dots {
		display: flex;
		justify-content: center;
		align-items: center; }

	.owl-dot {
		width: 10px;
		height: 10px;
		border-radius: 50%;
		transition: 0.4s;
		background-color: #dedede !important;
		cursor: pointer;
		margin-right: 10px;
		&:last-child {
			margin-right: 0; }
		&.active {
			width: 12px;
			height: 12px;
			background-color: $llblue !important; } } }
// news end

// press section start
.press {
	padding-bottom: 30px;
	&__item {
		background-color: #fff;
		border-radius: 5px;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 225px;
		margin-bottom: 30px;
		transition: transform 0.4s;
		&:hover {
			transform: scale(1.1); } } }
// press section end

// logoes block start
.logoes {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	div {
		opacity: 0.54;
		transition: opacity 0.4s;
		&:hover {
			opacity: 0.8; } }
	img {
		margin: 0 25px 20px; } }


// logoes block end

// subscribe section start
.contact {
	&-bg {
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%); }
	.container {
		position: relative;
		z-index: 1; } }

.form {
	max-width: 570px;
	margin: 0 auto;
	&__input {
		width: 100%;
		display: block;
		border-radius: 5px;
		background-color: #272f4c;
		margin-bottom: 20px;
		padding-left: 25px;
		color: #000000;
		font-size: 16px;
		font-weight: 300;
		line-height: 55px;
		height: 55px;
		border: none;
		transition: 0.4s;
		&:hover, &:focus, &.in {
			background-color: rgba(255,255,255,1);
			&::placeholder {
				color: $black;
				opacity: 0.5; } }
		&::placeholder {
			color: #ffffff;
			opacity: 0.5;
			transition: 0.4s; } }
	&__textarea {
		color: #000000;
		font-size: 16px;
		font-weight: 300;
		resize: none;
		height: 196px;
		padding-left: 25px;
		padding-top: 22px;
		display: block;
		border-radius: 5px;
		background-color: #272f4c;
		width: 100%;
		border: none;
		margin-bottom: 20px;
		transition: 0.4s;
		&:hover, &:focus, &.in {
			background-color: rgba(255,255,255,1);
			&::placeholder {
				color: $black;
				opacity: 0.5; } }
		&::placeholder {
			color: #ffffff;
			opacity: 0.5;
			transition: 0.4s; } }
	&__btn {
		cursor: pointer;
		width: 100%;
		text-align: center; }
	&__title {
		color: #ffffff;
		margin-bottom: 20px; }
	&__row {
		display: block;
		.form {
			&__input {
				width: 100%;
				margin-bottom: 10px;
				margin-right: 0; }
			&__btn {
				width: 100%; } } } }

.register-form {
	max-width: 570px;
	margin: 0;
	&__input {
		width: 100%;
		display: block;
		border-radius: 5px;
		background-color: #272f4c;
		margin-bottom: 20px;
		padding-left: 25px;
		color: #000000;
		font-size: 16px;
		font-weight: 300;
		line-height: 55px;
		height: 55px;
		border: none;
		transition: 0.4s;
		&:hover, &:focus, &.in {
			background-color: rgba(255,255,255,1);
			&::placeholder {
				color: $black;
				opacity: 0.5; } }
		&::placeholder {
			color: #ffffff;
			opacity: 0.5;
			transition: 0.4s; } }
	&__textarea {
		color: #000000;
		font-size: 16px;
		font-weight: 300;
		resize: none;
		height: 196px;
		padding-left: 25px;
		padding-top: 22px;
		display: block;
		border-radius: 5px;
		background-color: #272f4c;
		width: 100%;
		border: none;
		margin-bottom: 20px;
		transition: 0.4s;
		&:hover, &:focus, &.in {
			background-color: rgba(255,255,255,1);
			&::placeholder {
				color: $black;
				opacity: 0.5; } }
		&::placeholder {
			color: #ffffff;
			opacity: 0.5;
			transition: 0.4s; } }
	&__btn {
		cursor: pointer;
		width: 100%;
		text-align: center; }
	&__title {
		color: #ffffff;
		margin-bottom: 20px; }
	&__row {
		display: block;
		.form {
			&__input {
				width: 100%;
				margin-bottom: 10px;
				margin-right: 0; }
			&__btn {
				width: 100%; } } } }

label.error {
	display: none !important; }
// subscribe section end


// footer
.footer {
	padding-bottom: 80px;
	.logo {
		margin-bottom: 25px; }
	.logo__title {
		font-size: 27px; }
	.logo__img {
		height: 71px; } }

.copyright {
	font-size: 14px;
	color: #5c6586;
	margin-bottom: 25px; }

.subscribe {
	margin: 0;
	.form__btn {
		height: 55px;
		line-height: 55px;
		width: 100%; } }

.text-left {
	text-align: left; }

.package-list {
	list-style: disc;
	ul {
		list-style: circle; } }

.form__input {
	height: 55px !important;
	color: #8b8f9f; }

.form-control {
	background-color: #ffffff;
	font-size: 18px;
	&::placeholder {
		color: inherit; } }

@import "media"; // Always at the end
