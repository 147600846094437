.Header {}

.header .logo__img {
  transition: all 0.3s ease-in-out;
}

.header:not(.sticky) .logo__img {
  width: 200px;
  height: 95px;
  transform: translateY(10px);
}

.fixed-menu__header {
  .logo__title {
    display: none;
  }

  .logo__img {
    width: 180px;
    height: 75px;
  }
}

.fixed-menu__content {
  padding: 20px !important;
}