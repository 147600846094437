
.form-signin {
    width: 100%;
    max-width: 330px;
    padding: 15px;
    margin: auto;
    z-index: 2;
    position: relative;
  }
  
  .form-signin .checkbox {
    font-weight: 400;
  }
  
  .form-signin .form-floating:focus-within {
    z-index: 2;
  }
  
  .form-signin input[type="email"] {
    margin-bottom: -1px;
  }
  
  .form-signin input[type="password"] {
    margin-bottom: 10px;
  }
  