$accent: orange;

// Customization Bootstrap vars from "app/libs/bootstrap/scss/_variables.scss"
$grid-gutter-width: 30px;
$grid-columns: 12;
$grid-breakpoints: (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px);
$container-max-widths: (sm: 540px, md: 720px, lg: 960px, xl: 1140px);

$gutter: $grid-gutter-width;


$Raleway: 'Raleway';
$Catamaran: 'Catamaran';
$Roboto: 'Roboto';

$default-font: $Catamaran, sans-serif;

$black: #51475a;

$purpure: #7061b2;
$grey: #777777;

$blue: #1f2641;
$main: #aab2cd;
$orange: #ed824e;
$green: #10cb6c;
$lblue: #00cde4;
$llblue: #00d1e4;
