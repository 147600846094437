#file,input[type="file"] {
  display: inline-block;
  width: 100%;
  padding: 120px 0 0 0;
  height: 100px;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background: url(https://cdn1.iconfinder.com/data/icons/hawcons/32/698394-icon-130-cloud-upload-512.png) center center no-repeat #282f4c;
  border-radius: 5px;
  background-size: 60px 60px;
  background-position: center 20px;
  margin-bottom: 0;
}

.file-input {
  position: relative;

  &:after {
    content: 'Upload file';
    position: absolute;
    top: 80px;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    text-align: center;
    font-weight: 300;
    font-size: 14px;
  }
}