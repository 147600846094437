
  .feather {
    width: 16px;
    height: 16px;
    vertical-align: middle;
  }
  
  /*
   * Sidebar
   */
  
  .sidebar {
    font-size: .875rem;
  }
  
  @media (max-width: 767.98px) {
    .sidebar {
      top: 5rem;
    }
  }

  h6.sidebar-heading {
    font-size: 18px;
    background-color: #181d31;
    padding: 10px 15px 7px;
    border-radius: 3px;
  }
  
  .sidebar-sticky {
    position: relative;
    top: 0;
    height: calc(100vh - 48px);
    padding-top: .5rem;
    overflow-x: hidden;
    overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
  }
  
  .sidebar .nav-link {
    font-weight: 500;
    color: #aab2cd;
    font-size: 18px;
  }
  
  .sidebar .nav-item > ul {
      margin-left: 15px;
  }
  
  .sidebar .nav-link .feather {
    margin-right: 6px;
    color: #eaeaea;
  }
  
  .sidebar .nav-link.active {
    color: #ffffff;
  }
  
  .sidebar .nav-link:hover .feather,
  .sidebar .nav-link.active .feather {
    color: inherit;
  }
  
  .sidebar-heading {
    font-size: .75rem;
    text-transform: uppercase;
  }
  
  /*
   * Navbar
   */
  
  .navbar-brand {
    padding-top: .75rem;
    padding-bottom: .75rem;
    font-size: 1rem;
    background-color: rgba(0, 0, 0, .25);
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .25);
  }
  
  .navbar .navbar-toggler {
    top: .25rem;
    right: 1rem;
  }
  
  .navbar .form-control {
    padding: .75rem 1rem;
    border-width: 0;
    border-radius: 0;
  }
  
  .form-control-dark {
    color: #fff;
    background-color: rgba(255, 255, 255, .1);
    border-color: rgba(255, 255, 255, .1);
  }
  
  .form-control-dark:focus {
    border-color: transparent;
    box-shadow: 0 0 0 3px rgba(255, 255, 255, .25);
  }
  

  .plyr--video {
    max-width: 720px;
    height: auto;
  }

  .checkbox-container {
    background-color: white;
    padding: 15px;
    border-radius: 5px;
    height: 250px;
    overflow: auto;
    overflow: overlay;
    
    label {
      display: flex !important;
      line-height: 20px;
    }

    input {
      box-sizing: border-box;
      padding: 0;
      width: 20px;
      height: 20px !important;
      display: inline-block;
      position: relative;
      margin: 0;
      margin-right: 15px;
    }
  }
